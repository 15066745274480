@import "~@/assets/scss/variables.scss";




























































































































































































































































































































































fieldset.form-col {
  border: thin solid rgb(233, 236, 239);
  border-radius: 5px;
}

fieldset.form-col legend {
  font-size: 1em;
  padding-right: 1px;
  padding-left: 1px;
  width: auto;
  margin: 0;
  color: rgb(0, 0, 0);
}

.warn-icon {
  color: $msi-warning;
}

.graph-container {
  flex-basis: 0;
  min-height: 400px;
}

.min-height-fit {
  min-height: fit-content;
}
