@import "~@/assets/scss/variables.scss";


























































































































































































































































































































































































































































































































































































































.multiselect__tags {
  min-height: 43px;
  padding-top: 10px;
}

.multiselect__single {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
