@import "~@/assets/scss/variables.scss";





















































































































































































































































































.graph-container {
  flex-basis: 0;
  min-height: 400px;
}

.no-select {
  user-select: none;
}

.min-height-fit {
  min-height: fit-content;
}
