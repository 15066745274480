@import "~@/assets/scss/variables.scss";





































































































































































































.highcharts-container {
  min-height: 0;
  min-width: 0;
}
