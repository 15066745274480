@import "~@/assets/scss/variables.scss";
























































































































.analysis-container {
  height: calc(100vh - 105px)
}

.analysis-container .tab-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: fit-content;
}

.analysis-container .tab-pane.active {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: fit-content;
}

.tab-icon {
  color: #b3b3b3;
  margin-right: 14.5px;
}

.tab-icon:hover {
  color: #7e7d7d;
}

.min-height-fit {
  min-height: fit-content;
}
